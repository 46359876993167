import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        >
         <path
            d="M5800 8334 c-260 -23 -519 -137 -700 -308 l-51 -48 -47 11 c-116 25
-245 31 -747 31 l-530 1 0 -1633 0 -1633 81 3 c71 3 82 2 88 -14 4 -10 6 -173
6 -363 l-2 -346 214 0 213 0 2 354 c1 195 6 358 10 362 4 4 121 9 258 11 185
4 275 10 348 23 85 16 100 17 107 4 5 -8 36 -81 70 -164 33 -82 100 -249 149
-370 l89 -220 165 -3 165 -2 55 137 c31 76 97 239 148 363 50 124 112 276 136
338 33 82 51 116 67 122 11 4 62 14 111 20 161 22 341 86 452 162 23 15 46 28
52 28 8 0 12 -180 13 -582 l3 -583 215 0 215 0 2 1630 c2 1598 2 1632 -18
1730 -52 258 -164 478 -330 644 -138 138 -321 231 -544 277 -69 14 -376 26
-465 18z m410 -40 c250 -35 429 -121 593 -286 160 -161 262 -363 316 -626 14
-67 14 -68 -5 -86 -13 -13 -42 -16 -165 -16 -146 0 -151 1 -174 25 -14 13 -25
34 -25 47 0 23 -25 108 -41 140 -5 10 -9 23 -9 29 0 17 -35 96 -69 154 -75
132 -154 210 -266 264 -46 23 -91 41 -99 41 -9 0 -16 5 -16 11 0 5 -4 8 -9 4
-6 -3 -42 2 -82 11 -56 13 -106 15 -228 12 -140 -5 -165 -8 -247 -36 -223 -75
-366 -200 -480 -421 -50 -97 -107 -258 -118 -336 -3 -22 -11 -62 -17 -90 -13
-60 -21 -784 -10 -865 20 -141 45 -270 53 -270 4 0 8 -7 8 -15 0 -48 120 -326
149 -345 5 -3 19 -23 33 -45 71 -111 223 -215 388 -264 106 -31 306 -35 475
-9 92 14 237 72 288 116 9 8 17 12 17 8 0 -4 18 14 41 41 22 26 47 53 54 58 7
6 27 38 45 70 18 33 36 62 40 65 8 6 56 131 66 173 3 15 10 30 15 33 5 3 9 17
9 32 1 36 36 112 52 112 7 1 82 2 166 2 169 2 177 -1 166 -57 -50 -270 -156
-483 -329 -660 -41 -41 -77 -75 -81 -75 -5 0 -19 -11 -33 -24 -40 -38 -236
-134 -291 -142 -8 -1 -47 -11 -87 -22 -40 -11 -74 -18 -76 -15 -3 2 -23 -1
-45 -6 -66 -18 -270 -24 -388 -11 -223 24 -381 79 -549 193 -87 58 -163 129
-231 214 -22 26 -42 50 -45 53 -4 3 -21 30 -40 60 -18 30 -36 57 -39 60 -11 9
-87 175 -108 235 -49 140 -87 321 -96 460 -3 44 -5 220 -5 390 1 171 1 339 1
374 0 35 4 57 8 51 5 -9 8 -5 8 11 -1 46 6 94 15 106 5 7 8 22 6 35 -1 13 3
37 10 53 7 17 16 51 21 76 5 25 15 61 23 80 53 122 101 226 112 239 6 8 27 40
45 70 18 30 36 57 39 60 4 3 20 23 36 45 27 37 76 88 156 160 52 47 147 109
219 142 90 42 197 83 217 83 9 0 30 5 47 10 56 20 260 39 356 34 52 -3 147
-12 210 -20z m-112 -305 c104 -11 222 -46 288 -84 146 -85 263 -267 324 -504
26 -98 24 -101 -58 -99 -29 0 -54 -1 -56 -3 -2 -2 -65 -159 -140 -349 -76
-190 -144 -358 -151 -375 -7 -16 -57 -140 -110 -275 -54 -135 -101 -251 -105
-258 -3 -7 -6 128 -6 300 1 337 -8 452 -50 619 -43 176 -111 330 -213 484 -54
83 -230 263 -318 325 -35 25 -63 48 -63 52 0 13 79 66 144 97 148 69 315 92
514 70z m-1166 -31 l67 -13 -55 -75 c-30 -41 -57 -83 -60 -92 -3 -10 -10 -18
-15 -18 -5 0 -9 -6 -9 -13 0 -18 -36 -51 -48 -43 -5 3 -153 6 -328 6 l-319 1
-21 -25 c-12 -13 -25 -22 -28 -19 -7 7 -7 3 -7 -204 0 -127 -2 -143 -17 -145
-9 -1 -57 -2 -107 -2 l-90 -1 -5 -1260 c-5 -1192 -6 -1260 -22 -1259 -10 1
-33 2 -52 3 -21 1 -36 7 -40 17 -3 9 -6 718 -6 1577 0 1200 3 1563 12 1575 10
12 88 13 548 9 383 -4 554 -9 602 -19z m596 -265 c7 -7 17 -13 21 -13 10 0
110 -99 128 -127 8 -13 21 -23 28 -23 7 0 18 -13 25 -30 7 -16 16 -30 21 -30
5 0 9 -7 9 -16 0 -10 6 -14 14 -11 10 4 12 0 9 -14 -3 -10 0 -19 6 -19 6 0 11
-7 11 -15 0 -9 7 -18 15 -21 16 -6 45 -58 45 -81 0 -7 3 -13 8 -13 8 0 32 -49
32 -67 0 -7 4 -13 10 -13 5 0 12 -17 16 -37 3 -21 15 -58 24 -83 10 -25 26
-72 35 -105 9 -33 21 -71 25 -85 34 -99 50 -616 26 -806 -10 -71 -18 -139 -19
-151 0 -12 -5 -19 -9 -17 -4 3 -8 -10 -8 -28 0 -31 -44 -161 -87 -258 -11 -25
-33 -76 -48 -115 -15 -38 -39 -91 -54 -117 -25 -46 -28 -48 -61 -42 -78 13
-162 45 -227 86 -44 28 -55 48 -27 48 11 0 15 6 11 19 -3 12 0 18 7 15 12 -4
52 73 48 94 -2 7 2 11 8 10 14 -4 36 52 66 172 14 52 29 104 34 115 10 20 12
33 31 205 13 111 5 603 -10 643 -5 14 -12 47 -14 74 -3 26 -7 55 -10 63 -3 8
-15 49 -27 90 -27 92 -42 130 -51 125 -3 -3 -4 6 -2 18 2 14 -10 42 -32 75
-19 30 -35 58 -35 63 0 5 -4 9 -10 9 -5 0 -10 7 -10 15 0 9 -7 18 -15 21 -8 4
-12 10 -10 15 3 4 -3 14 -15 21 -11 7 -20 18 -20 24 0 6 -16 26 -36 45 -20 19
-46 44 -57 57 -12 13 -39 33 -59 45 -21 11 -38 27 -38 35 0 24 68 128 115 177
l45 45 52 -37 c29 -20 59 -43 66 -50z m-712 -36 c5 -4 3 -16 -3 -27 -17 -27
-71 -169 -94 -247 -31 -106 -58 -276 -65 -410 -4 -68 -10 -123 -14 -123 -4 0
-47 101 -96 225 l-89 225 -150 2 -150 3 -3 185 -2 185 329 -5 c181 -3 333 -9
337 -13z m480 -195 c158 -133 254 -294 314 -527 46 -180 53 -270 47 -612 -5
-302 -6 -310 -35 -426 -32 -124 -62 -210 -90 -261 -51 -94 -85 -146 -95 -146
-6 0 -31 21 -55 47 -138 148 -242 381 -288 646 -21 126 -31 644 -15 828 18
198 101 508 137 509 6 0 42 -26 80 -58z m-948 -934 c29 -73 167 -414 307 -758
139 -344 250 -629 246 -633 -34 -34 -580 -51 -573 -18 3 16 -34 1290 -43 1444
-3 53 -2 97 3 97 4 0 31 -60 60 -132z m2415 -5 c-10 -298 -22 -461 -37 -533
-55 -256 -169 -456 -314 -549 -55 -35 -159 -77 -169 -68 -3 4 24 80 60 169
124 306 294 723 354 868 33 80 68 169 77 198 10 28 21 52 26 52 4 0 5 -62 3
-137z m-1345 -1449 c30 -14 50 -29 46 -32 -5 -4 -21 -18 -36 -30 l-26 -24 -26
52 c-14 28 -26 54 -26 56 0 8 14 4 68 -22z m-479 -39 c51 -105 80 -194 65
-196 -5 -1 -18 -3 -29 -5 -11 -2 -51 -8 -88 -14 -37 -5 -72 -12 -77 -15 -7 -4
-286 -8 -442 -5 l-28 0 0 125 c0 69 3 125 8 125 4 0 96 1 205 3 108 1 197 5
197 9 0 5 29 8 65 8 40 0 65 4 65 11 0 28 38 -1 59 -46z m699 -16 c17 -6 32
-14 32 -18 0 -5 -27 -77 -61 -162 -34 -85 -65 -165 -71 -179 l-10 -25 -13 25
c-21 40 -105 259 -105 274 0 7 30 34 67 60 71 49 78 50 161 25z"
          />
          <path
            d="M6350 3974 c-65 -28 -109 -83 -136 -169 -12 -39 -12 -40 -22 -15 -5
14 -22 36 -37 50 -28 27 -34 27 -223 22 -91 -2 -93 -2 -103 -29 -10 -26 -12
-27 -23 -11 -18 25 -79 48 -125 48 -65 0 -115 -25 -141 -72 -20 -36 -22 -45
-11 -74 10 -27 9 -38 -3 -61 -14 -27 -15 -23 -16 70 0 84 3 103 20 124 24 31
26 76 4 97 -20 20 -156 23 -172 4 -10 -13 -73 -169 -87 -216 -4 -13 -11 -22
-17 -20 -6 2 -27 49 -48 105 -58 154 -47 143 -145 143 -73 0 -86 -3 -95 -19
-16 -31 -12 -76 10 -96 18 -16 20 -31 20 -137 l0 -118 -25 0 c-25 0 -25 1 -25
91 0 105 -14 139 -68 165 -49 23 -142 15 -183 -15 l-31 -23 -14 21 c-9 12 -26
21 -40 21 -20 0 -24 5 -24 28 0 41 -21 63 -67 69 -33 4 -45 1 -62 -16 -11 -11
-21 -34 -21 -49 0 -42 -34 -58 -82 -37 -54 22 -117 19 -172 -9 l-47 -24 -13
32 c-37 89 -120 126 -281 126 -115 0 -133 -8 -141 -62 -5 -31 -2 -41 20 -63
25 -25 26 -31 26 -135 0 -98 -2 -110 -19 -120 -24 -12 -35 -58 -22 -93 13 -34
74 -44 192 -29 93 11 161 39 179 72 13 23 30 26 30 5 0 -8 13 -29 29 -47 l29
-33 124 -2 c87 -1 131 3 152 13 24 12 32 11 59 -2 23 -12 45 -15 91 -10 34 4
70 7 81 6 192 -6 465 -5 483 2 15 5 38 4 62 -3 31 -9 44 -9 68 3 25 13 33 13
53 2 30 -16 115 -18 175 -3 29 8 48 8 57 2 15 -13 613 -14 645 -2 12 5 22 14
22 21 0 7 5 20 10 28 8 12 12 11 32 -7 38 -36 92 -53 165 -53 74 1 142 20 155
45 8 13 14 11 45 -12 32 -25 45 -28 109 -28 59 0 78 4 103 23 37 27 47 28 54
4 5 -23 45 -32 136 -32 88 0 115 15 119 63 4 45 -9 65 -46 69 -24 3 -27 8 -30
44 l-3 40 60 0 61 -1 0 -111 c0 -98 -2 -113 -20 -129 -24 -22 -27 -77 -4 -100
13 -13 36 -15 122 -13 105 3 107 3 120 30 17 36 6 75 -27 93 l-26 14 41 1 c54
0 101 33 131 89 18 35 23 60 23 121 0 66 -4 82 -27 118 -42 65 -61 70 -273 66
-102 -2 -220 -3 -264 -3 -68 0 -80 -3 -93 -21 l-14 -20 -31 21 c-60 43 -162
35 -223 -17 l-30 -26 -3 63 c-2 40 -9 70 -19 81 -39 43 -175 61 -243 32z m125
-127 c15 -58 19 -62 77 -62 31 0 54 -3 51 -8 -3 -4 -10 -29 -16 -55 -11 -45
-14 -47 -52 -52 -35 -4 -41 -9 -50 -37 -7 -25 -15 -33 -33 -33 -58 0 -92 49
-92 134 0 89 32 132 90 120 14 -3 25 -6 25 -7z m-2515 -15 c42 -34 44 -162 4
-203 -11 -10 -30 -20 -44 -21 l-25 -3 -3 109 c-1 61 0 116 2 123 7 18 40 16
66 -5z m692 -113 c12 -19 11 -27 -9 -70 -34 -71 -53 -65 -53 15 0 65 2 68 41
75 4 0 13 -9 21 -20z m1207 7 c7 -8 11 -37 9 -68 -2 -41 -7 -54 -20 -56 -16
-3 -18 5 -18 67 0 71 7 84 29 57z m919 2 c13 -13 16 -85 6 -112 -3 -9 -14 -16
-24 -16 -22 0 -30 19 -30 70 0 65 19 87 48 58z m-727 -5 c0 -5 2 -26 4 -48 6
-52 -2 -75 -26 -75 -17 0 -19 7 -19 58 0 32 3 62 7 65 9 9 33 9 34 0z m1372
-22 c3 -16 3 -45 0 -65 -5 -32 -9 -36 -34 -36 -29 0 -29 1 -29 58 0 65 3 72
35 72 17 0 24 -8 28 -29z m-2986 -73 c-2 -18 -4 -4 -4 32 0 36 2 50 4 33 2
-18 2 -48 0 -65z m1783 -9 c0 -10 -4 -19 -10 -19 -5 0 -10 12 -10 26 0 14 4
23 10 19 6 -3 10 -15 10 -26z m-1413 -15 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
